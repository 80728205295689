import { Controller } from "stimulus"

export default class extends Controller {
  change(event) {
    const file_name = event.target.files[0].name
    const form = event.target.parentNode.parentNode;
    const current_target = $(event.target);
    current_target.siblings('label').text(file_name);
    Rails.fire(form, 'submit');
  }
}
