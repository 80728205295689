import { Controller } from "stimulus"

let dashboardDistrictExpenseChart;

export default class extends Controller {
  static targets = [ "chart" ];
  static values = { beginTime: String, endTime: String, pause: Number };

  connect() {
    dashboardDistrictExpenseChart = echarts.init(this.chartTarget);

    const district_expense_option = {
      title : {
        text: '各区消费对比',
        subtext: '查询时间、消费金额',
        x:'center'
      },
      tooltip : {
        trigger: 'axis',
        axisPointer : {
          type : 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
            pixelRatio: 2
          }
        }
      },
      xAxis : [
        {
          type: 'category',
          data: [],
        }
      ],
      yAxis : [
        {
          type: 'value'
        }
      ],
      series : [
        {
          name: '消费金额',
          type: 'bar',
          color: ['#7289ab', '#91ca8c','#f49f42'],
          data: [],
        }
      ]
    };

    dashboardDistrictExpenseChart.setOption(district_expense_option);

    let thatBeginTime = this.beginTimeValue;
    let thatEndTime = this.endTimeValue;

    setTimeout(() => {
      $.get('/chart/district_expense.json', { begin_time: this.beginTimeValue, end_time: this.endTimeValue })
        .done(function (data) {
          dashboardDistrictExpenseChart.setOption({
              title: {
                subtext: `${thatBeginTime} - ${thatEndTime} 总消费金额 ${data.expense_sums.reduce((a, b) => a + b, 0)}`
              },
              xAxis: {
                  data: data.categories
              },
              series: [{
                  name: '消费金额',
                  data: data.expense_sums
              }]
          });
      });
      dashboardDistrictExpenseChart.resize();
    }, this.pauseValue);
  }

  layout() {
    dashboardDistrictExpenseChart.resize();
  }

  disconnect() {
    dashboardDistrictExpenseChart.dispose();
  }
}
