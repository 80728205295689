document.addEventListener("turbolinks:load", function() {
  // Make coreui toggle happy
  $('.sidebar').sidebar();
  $('.aside-menu')['aside-menu']();

  $('#datetimepicker_start').datetimepicker({locale: 'zh-cn', format: 'YYYY-MM-DD HH:mm'});
  $('#datetimepicker_end').datetimepicker({locale: 'zh-cn', format: 'YYYY-MM-DD HH:mm'});
  $('#datetimepicker_date').datetimepicker({locale: 'zh-cn', format: 'YYYY-MM-DD'});
  $('[data-toggle="tooltip"]').tooltip();
});
