import { Controller } from "stimulus"

let dashboardPopularProjectCharts = [];
let projectIdsPerCharts = [];

export default class extends Controller {
  static targets = [ "chart" ];
  static values = { beginTime: String, endTime: String, categoryId: Number, categoryName: String, pause: Number };

  connect() {
    let thatBeginTime = this.beginTimeValue;
    let thatEndTime = this.endTimeValue;
    let thatCategoryId = this.categoryIdValue;
    let thatCategoryName = this.categoryNameValue;

    dashboardPopularProjectCharts[thatCategoryId] = echarts.init(this.chartTarget);

    const district_delivery_option = {
      title : {
        text: `${thatCategoryName}配送项目活跃度`,
        x:'center'
      },
      tooltip : {
        trigger: 'axis',
        axisPointer : {
          type : 'shadow'
        }
      },
      grid: {
        left: '2%',
        right: '2%',
        bottom: '2%',
        containLabel: true
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
            pixelRatio: 2
          }
        }
      },
      xAxis : [
        {
          type: 'category',
          axisLabel: {
            showMinLabel: true,
            rotate: -40,
            fontSize: 9,
          },
          data: [],
        }
      ],
      yAxis : [
        {
          type: 'value'
        }
      ],
      series : [
        {
          name: '配送数量',
          type: 'bar',
          color: ['#73b9bc','#7289ab', '#91ca8c','#f49f42'],
          data: [],
        }
      ]
    };

    function drill_down_district_delivery_detail(params) {
      if (params.componentType === 'series') {
        if (params.seriesType === 'bar') {
          const project_id = projectIdsPerCharts[thatCategoryId][params.dataIndex];
          const sent_data = { project_id, begin_time: thatBeginTime, end_time: thatEndTime };
          $.ajax('/chart/popular_project/drill_down.js', {
            data: sent_data,
            dataType: 'script'
          });
        }
      }
    }

    dashboardPopularProjectCharts[thatCategoryId].setOption(district_delivery_option);
    dashboardPopularProjectCharts[thatCategoryId].on('click', drill_down_district_delivery_detail);

    setTimeout(() => {
      $.get('/chart/popular_project.json', { begin_time: thatBeginTime, end_time: thatEndTime, category_id: thatCategoryId })
        .done(function (data) {
          projectIdsPerCharts[thatCategoryId] = data.project_ids;
          dashboardPopularProjectCharts[thatCategoryId].setOption({
            title: {
              subtext: `${thatBeginTime} - ${thatEndTime} 配送总数 ${data.delivery_counts.reduce((a, b) => a + b, 0)}`
            },
            xAxis: {
              data: data.categories
            },
            series: [{
              name: '配送数量',
              data: data.delivery_counts
            }]
          });
      });
      dashboardPopularProjectCharts[thatCategoryId].resize();
    }, this.pauseValue);
  }

  layout() {
    dashboardPopularProjectCharts[this.categoryIdValue].resize();
  }

  disconnect() {
    dashboardPopularProjectCharts[this.categoryIdValue].dispose();
  }
}
