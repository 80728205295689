import { Controller } from "stimulus"

let articleSelect;

export default class extends Controller {
  connect() {
    articleSelect = $(this.element).selectize({
      plugins: ["remove_button"],
      valueField: "id",
      labelField: "title",
      searchField: "title",
      create: false,
      load: function (query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: "/ui/article_select.json?q=" + encodeURIComponent(query),
          type: "GET",
          error: function () {
            callback();
          },
          success: function (res) {
            callback(res.articles);
          },
        });
      },
    });
  }

  disconnect() {
    const need_destroy = articleSelect[0].selectize;
    if(need_destroy) {
      need_destroy.destroy();
    }
  }
}
