import { Controller } from "stimulus"

let instructorGenderPieChart;

export default class extends Controller {
  static targets = [ "chart" ];
  static values = { beginTime: String, endTime: String, pause: Number };

  connect() {
    let thatBeginTime = this.beginTimeValue;
    let thatEndTime = this.endTimeValue;

    instructorGenderPieChart = echarts.init(this.chartTarget);

    const instructor_gender_option = {
      title : {
        text: '教练男女比例',
        x:'center'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '10%',
        left: 'center'
      },
      series: [
        {
          name: '性别',
          type: 'pie',
          radius: ['35%', '60%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center',
            formatter: '{b}: {d}%'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '16',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    };

    instructorGenderPieChart.setOption(instructor_gender_option);

    setTimeout(() => {
      $.get('/chart/instructor_gender.json', { begin_time: thatBeginTime, end_time: thatEndTime })
        .done(function (data) {
          instructorGenderPieChart.setOption({
              series: [{
                data: data.genders
              }]
          });
      });
      instructorGenderPieChart.resize();
    }, this.pauseValue);
  }

  layout() {
    instructorGenderPieChart.resize();
  }

  disconnect() {
    instructorGenderPieChart.dispose();
  }
}
