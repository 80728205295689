import { Controller } from "stimulus"

export default class extends Controller {
  static values = { districtId: Number, subdistrictId: Number }

  connect() {
    const url_param = $.param({
      district_id: this.districtIdValue,
      subdistrict_id: this.subdistrictIdValue
    });
    $.ajax({
      url: "/counter/delivery_is_abnormal.json?" + url_param,
      type: "GET",
      error: function () {
        $('#delivery-is-abnormal-count').text('N/A');
        $('#delivery-is-abnormal-table-count').text('N/A');
      },
      success: function (res) {
        $('#delivery-is-abnormal-count').text(res.apply_num);
        $('#delivery-is-abnormal-table-count').text(res.apply_num);
        $('#delivery-is-abnormal-table-body').html(res.table_body);
      },
    });
  }

  jump() {
    document.getElementById("delivery-is-abnormal-table-count").scrollIntoView({behavior: 'smooth', block: 'center'});
  }
}
