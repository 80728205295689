import { Controller } from "stimulus"

export default class extends Controller {
  static values = { applyId: Number, inPage: Number, extend: Number }

  toggle() {
    switch (this.extendValue) {
      case 1:
        this.extendValue = 0;
        $(`.js-apply-row-${this.applyIdValue}`).collapse('hide');
        break;
      case 0:
        this.extendValue = 1;
        $(`.js-apply-row-${this.applyIdValue}`).collapse('show');
        break;
      default:
        $.ajax('/ui/apply_row', {
          data: { apply_id: this.applyIdValue, in_page: this.inPageValue },
          dataType: 'script'
        });
        this.extendValue = 1;
    }
  }
}
