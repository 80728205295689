import { Controller } from "stimulus"

let dashboardDistrictUnitChart;

export default class extends Controller {
  static targets = [ "chart" ];
  static values = { pause: Number };

  connect() {
    dashboardDistrictUnitChart = echarts.init(this.chartTarget);

    const district_unit_option = {
      title : {
        text: '各区体育社区数量',
        x:'center'
      },
      tooltip : {
        trigger: 'axis',
        axisPointer : {
          type : 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
            pixelRatio: 2
          }
        }
      },
      xAxis : [
        {
          type: 'category',
          data: [],
        }
      ],
      yAxis : [
        {
          type: 'value'
        }
      ],
      series : [
        {
          name: '社区数量',
          type: 'bar',
          color: ['#7289ab', '#91ca8c','#f49f42'],
          data: [],
        }
      ]
    };

    dashboardDistrictUnitChart.setOption(district_unit_option);

    setTimeout(() => {
      $.get('/chart/district_unit.json').done(function (data) {
          dashboardDistrictUnitChart.setOption({
              title: {
                subtext: `社区总数 ${data.unit_counts.reduce((a, b) => a + b, 0)}`
              },
              xAxis: {
                data: data.categories
              },
              series: [{
                name: '社区数量',
                data: data.unit_counts
              }]
          });
      });
      dashboardDistrictUnitChart.resize();
    }, this.pauseValue);
  }

  layout() {
    dashboardDistrictUnitChart.resize();
  }

  disconnect() {
    dashboardDistrictUnitChart.dispose();
  }
}
