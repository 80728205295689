import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "datatable" ];

  connect() {
    const normalColumns = [
      {"data": "id"},
      {"data": "user_name"},
      {"data": "user_unit"},
      {"data": "transaction_type"},
      {"data": "transaction_amount"},
      {"data": "created_at"},
      {"data": "comment"},
    ];

    $(this.datatableTarget).dataTable({
      "processing": true,
      "serverSide": true,
      "autoWidth": false,
      "ajax": $(this.datatableTarget).data('source'),
      "pagingType": "full_numbers",
      "columns": normalColumns,
      "order": [[ 1, 'DESC' ]],
      stateSave: true,
      stateSaveCallback: function(settings, data) {
          localStorage.setItem('DT_user-transactions', JSON.stringify(data));
        },
      stateLoadCallback: function(settings) {
        const data_state = JSON.parse(localStorage.getItem('DT_user-transactions'));
        const search_hash = data_state && data_state['search'];
        if (search_hash) {
          $('#search-box').val(search_hash['search']);
        }
        return data_state;
        }
    });
  }

  do_search(mouseEvent) {
    const searchText = $('#search-box').val();
    $(this.datatableTarget).DataTable().search(searchText).draw();
    mouseEvent.preventDefault();
  }

  disconnect() {
    $(this.datatableTarget).DataTable().destroy();
  }
}
