import { Controller } from "stimulus"

function get5DaysArray() {
  const today = new Date();
  const endDate = new Date();
  endDate.setDate(today.getDate() + 6);
  for(var arr=[], dt=today; dt<=endDate; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  return arr;
};

export default class extends Controller {
  static values = { disableDates: Boolean, scheduledTimes: Array };

  connect() {
    if (this.disableDatesValue) {
      $('#datetimepicker-applies').datetimepicker({ locale: 'zh-cn', format: 'YYYY-MM-DD HH:mm', disabledDates: get5DaysArray() });
    } else {
      $('#datetimepicker-applies').datetimepicker({ locale: 'zh-cn', format: 'YYYY-MM-DD HH:mm' });
    }
  }

  new_time(event) {
    const begin_time = $('#apply-begin-time').val();
    if ((this.scheduledTimesValue.indexOf(begin_time) > -1) || (Date.parse(begin_time.replace(" ", "T")) < new Date()) || (this.scheduledTimesValue.length >= 10)) {
      $('#apply-begin-time').val('');
    } else if (begin_time != "") {
      let to_change_array = this.scheduledTimesValue;
      to_change_array.push(begin_time);
      this.scheduledTimesValue = to_change_array;
      const transform = {'<>':'li','class':'list-group-item','text': `${this.scheduledTimesValue.length}: ${begin_time}`,
        'html':[
          {'<>':'input','type':'hidden','name':'apply[implement_times][]','value':begin_time},
          {'<>':'button','type':'button','class':'close','data-dismiss':'alert','aria-label':'Close',
            'html':[{'<>':'span','aria-hidden':'true','data-action':'click->ui--distribute-time-add#remove_time','html':'&times;'}]
          }
        ]};
      const html_result = json2html.transform({ begin_time }, transform);
      $('#begin_time_lists').append(html_result);
    }
  }

  remove_time(event) {
    const current_target = $(event.target);
    const to_remove_div = current_target.parent().parent();

    const to_remove_time = to_remove_div[0].children[0].value;
    let to_change_array = this.scheduledTimesValue;

    const index = to_change_array.indexOf(to_remove_time);
    if (index !== -1) {
      to_change_array.splice(index, 1);
    }

    this.scheduledTimesValue = to_change_array;
    to_remove_div.remove();
  }

  disconnect() {
    $('#datetimepicker-applies').datetimepicker("destroy");
  }
}
