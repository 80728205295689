import { Controller } from "stimulus"

export default class extends Controller {
  static values = { resourceId: Number, url: String }

  click(event) {
    $.ajax(this.urlValue, {
      data: { resource_id: this.resourceIdValue },
      dataType: 'script'
    });
    event.preventDefault();
  }
}
