import { Controller } from "stimulus"

export default class extends Controller {
  static values = { categoryId: Number, isRemote: Boolean }

  click(event) {
    const current_target = $(event.target);
    const category_id = this.categoryIdValue
    current_target.siblings().removeClass('active');
    current_target.addClass('active');
    $.ajax('/ui/category_click', {
      data: { category_id },
      dataType: 'script'
    });
    if (this.isRemoteValue) {
      $('.js-non_remote-field').hide();
      $('.js-is_remote-field').show();
    } else {
      $('.js-is_remote-field').hide();
      $('.js-non_remote-field').show();
    }
    event.preventDefault();
  }
}
