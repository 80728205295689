import { Controller } from "stimulus"

let subDeliveryCharts = [];

export default class extends Controller {
  static targets = [ "chart" ];
  static values = { beginTime: String, endTime: String, districtId: Number, pause: Number };

  connect() {
    subDeliveryCharts[this.districtIdValue] = echarts.init(this.chartTarget);

    const subdistrict_delivery_option = {
      title : {
        text: '各街道配送数量对比',
        x:'center'
      },
      tooltip : {
        trigger: 'axis',
        axisPointer : {
          type : 'shadow'
        }
      },
      legend: {
        data: ['未知', '社区配送', '楼宇配送', '园区配送', '校区配送', '单位配送', '企业配送'],
        left: 'center',
        top: '42'
      },
      grid: {
        left: '5%',
        right: '5%',
        top: '21%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
            pixelRatio: 2
          }
        }
      },
      xAxis : [
        {
          type: 'category',
          axisLabel: {
            showMinLabel: true,
            rotate: -40,
            fontSize: 9,
          },
          data: [],
        }
      ],
      yAxis : [
        {
          type: 'value'
        }
      ],
      series : [{
          name: '合计',
          type: 'bar',
          barGap: '-100%',
          data: []
        },{
          name: '未知',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '社区配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '楼宇配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '园区配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '校区配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '机关配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '其他配送',
          type: 'bar',
          stack: '总量',
          data: []
        }
      ]
    };

    subDeliveryCharts[this.districtIdValue].setOption(subdistrict_delivery_option);

    let thatDistrictId = this.districtIdValue;
    let thatBeginTime = this.beginTimeValue;
    let thatEndTime = this.endTimeValue;

    setTimeout(() => {
      $.get('/chart/subdistrict_delivery.json', { begin_time: thatBeginTime, end_time: thatEndTime, district_id: this.districtIdValue })
        .done(function (data) {
          subDeliveryCharts[thatDistrictId].setOption({
              title: {
                text: `${data.district_title || '各街道'}配送数量对比`,
                subtext: `${thatBeginTime} - ${thatEndTime} 配送总数 ${data.delivery_counts.reduce((a, b) => a + b, 0)}`
              },
              xAxis: {
                data: data.categories
              },
              series: [{
                  name: '合计',
                  data: data.delivery_counts
                },{
                  name: '未知',
                  data: data.unknown_counts
                },{
                  name: '社区配送',
                  data: data.community_distribute_counts
                },{
                  name: '楼宇配送',
                  data: data.building_floor_distribute_counts
                },{
                  name: '园区配送',
                  data: data.street_block_distribute_counts
                },{
                  name: '校区配送',
                  data: data.campus_distribute_counts
                },{
                  name: '机关配送',
                  data: data.jiguan_distribute_counts
                },{
                  name: '其他配送',
                  data: data.other_distribute_counts
                }
              ]
          });
      });
      subDeliveryCharts[thatDistrictId].resize();
    }, this.pauseValue);
  }

  layout() {
    subDeliveryCharts[this.districtIdValue].resize();
  }

  disconnect() {
    subDeliveryCharts[this.districtIdValue].dispose();
  }
}
