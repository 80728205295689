import { Controller } from "stimulus"

window.initLiveMap = function () {
  const mapPoints = $('#live-map').data("live-map-map_points");
  const prefixPath = $('#live-map').data("live-map-prefix_path");

  // Locate To Shanghai
  const center = new TMap.LatLng(31.228177,121.487003);
  const zoom = 10;

  window.live_map = new TMap.Map("live-map", {
    center,
    zoom
  });

  const geometries = mapPoints.map(function(m) {
    const properties = {
      id: m.id,
      distribute_id: m.distribute_id,
      title: m.title,
      unit: m.unit,
      project_name: m.project_name,
      bg_style: m.bg_style,
      implement_time: m.implement_time,
      coaches: m.coaches,
      min_people: m.min_people
    }

    return {
      styleId: m.mark_style,
      position: new TMap.LatLng(m.lat, m.lng),
      properties
    };
  });

  var marker = new TMap.MultiMarker({
    id: 'marker-layer',
    map: window.live_map,
    styles: {
      "marker_alert": new TMap.MarkerStyle({
          "width": 21,
          "height": 30,
          "anchor": { x: 14, y: 24 },
          "src": "/images/marker_alert.png"
      }),
      "marker_ing": new TMap.MarkerStyle({
          "width": 21,
          "height": 30,
          "anchor": { x: 14, y: 24 },
          "src": "/images/marker_ing.png"
      }),
      "marker_noupload": new TMap.MarkerStyle({
          "width": 21,
          "height": 30,
          "anchor": { x: 14, y: 24 },
          "src": "/images/marker_noupload.png"
      }),
      "marker_today": new TMap.MarkerStyle({
          "width": 21,
          "height": 30,
          "anchor": { x: 14, y: 24 },
          "src": "/images/marker_today.png"
      }),
      "marker_week_end": new TMap.MarkerStyle({
          "width": 21,
          "height": 30,
          "anchor": { x: 14, y: 24 },
          "src": "/images/marker_week_end.png"
      }),
      "marker_week_ing": new TMap.MarkerStyle({
          "width": 21,
          "height": 30,
          "anchor": { x: 14, y: 24 },
          "src": "/images/marker_week_ing.png"
      })
    },
    geometries: geometries
  });

  var infoWindow = new TMap.InfoWindow({
    map: window.live_map,
    position: new TMap.LatLng(31.228177, 121.487003),
    offset: { x: -4, y: -14 } // 设置信息窗相对position偏移像素
  });
  infoWindow.close();

  function info_window_close() {
    infoWindow.close();
  }

  live_map.on("rightclick", info_window_close);

  function marker_click(evt) {
    infoWindow.open();
    infoWindow.setPosition(evt.geometry.position);
    const props = evt.geometry.properties;

    const content = `
      <div class="card text-white mb-3 ${props.bg_style}">
        <div class="card-header">${props.title}</div>
        <div class="card-body text-primary">
          <table class="table text-white">
            <thead class="table-borderless">
              <tr>
                <th scope="col">配送 ID</th>
                <th scope="col">实施时间</th>
                <th scope="col">申请组织</th>
                <th scope="col">项目名称</th>
                <th scope="col">教练信息</th>
                <th scope="col">活动报名人数</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><a href="/${prefixPath}/applies/${props.id}" target="_blank" class="text-white">SCCSA_${props.id}</a></td>
                <td><a href="/${prefixPath}/applies/${props.id}/distributes/${props.distribute_id}" target="_blank" class="text-white">${props.implement_time}</a></td>
                <td>${props.unit}</td>
                <td>${props.project_name}</td>
                <td>${props.coaches}</td>
                <td>${props.min_people}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>`;
    infoWindow.dom.children[0].style["text-align"] = "left";
    infoWindow.dom.children[0].style["line-height"] = "1";
    infoWindow.dom.children[0].style["padding"] = "unset";
    infoWindow.setContent(content);
  }

  marker.on("click", marker_click);
}

export default class extends Controller {
  connect() {
    if (typeof TMap != 'object') {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://map.qq.com/api/gljs?v=1.exp&key=2WEBZ-RABKW-ZDQRR-ONT62-COIC7-VOBOV&callback=initLiveMap";
      document.body.appendChild(script);
    } else {
      window.initLiveMap();
    }
  }

  disconnect() {
    window.live_map.destroy();
    window.live_map = null;
  }
}
