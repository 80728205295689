import { Controller } from "stimulus"

let instructorRevenueChart;

export default class extends Controller {
  static targets = [ "chart" ];
  static values = { instructorIds: Array, instructorNames: Array, distributeAmounts: Array, transportationFees: Array, managementFees: Array };

  connect() {
    instructorRevenueChart = echarts.init(this.chartTarget, 'default', { useDirtyRect: true });

    let thatTransportationFees = this.transportationFeesValue;
    let thatManagementFees = this.managementFeesValue;

    function totalSum(amount_value, index) {
      return parseInt(amount_value + thatTransportationFees[index] + thatManagementFees[index]);
    }

    const totalSumValue = this.distributeAmountsValue.map(totalSum);

    const option = {
      title: {
        text: '教练收入排名',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['配送费用', '交通费用', '管理费用'],
        left: 'center',
        top: '30'
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '60',
        containLabel: true
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
              pixelRatio: 2
          }
        }
      },
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: this.instructorNamesValue
      },
      series: [{
          name: '合计',
          type: 'bar',
          barGap: '-100%',
          data: totalSumValue,
          itemStyle: {
            color: '#DDDDDD'
          },
          label: {
            show: true,
            color: '#353535',
            position: 'right'
          }
        },{
          name: '配送费用',
          type: 'bar',
          stack: '总量',
          data: this.distributeAmountsValue
        },{
          name: '交通费用',
          type: 'bar',
          stack: '总量',
          label: {
            show: true,
            position: 'insideRight'
          },
          data: thatTransportationFees
        },,{
          name: '管理费用',
          type: 'bar',
          stack: '总量',
          label: {
            show: true,
            position: 'insideRight'
          },
          data: thatManagementFees
        }
      ]
    };

    instructorRevenueChart.setOption(option);
  }

  layout() {
    instructorRevenueChart.resize();
  }

  disconnect() {
    instructorRevenueChart.dispose();
  }
}
