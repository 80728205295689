import { Controller } from "stimulus"

let dashboardDistrictDeliveryChart;

export default class extends Controller {
  static targets = [ "chart" ];
  static values = { beginTime: String, endTime: String, pause: Number };

  connect() {
    dashboardDistrictDeliveryChart = echarts.init(this.chartTarget);

    const district_delivery_option = {
      title : {
        text: '各区配送数量对比',
        x:'center'
      },
      tooltip : {
        trigger: 'axis',
        axisPointer : {
          type : 'shadow'
        }
      },
      legend: {
        data: ['未知', '社区配送', '楼宇配送', '园区配送', '校区配送', '单位配送', '企业配送'],
        left: 'center',
        top: '42'
      },
      grid: {
        left: '3%',
        right: '4%',
        top: '21%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
            pixelRatio: 2
          }
        }
      },
      xAxis : [
        {
          type: 'category',
          data: [],
        }
      ],
      yAxis : [
        {
          type: 'value'
        }
      ],
      series : [{
          name: '合计',
          type: 'bar',
          barGap: '-100%',
          data: []
        },{
          name: '未知',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '社区配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '楼宇配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '园区配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '校区配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '机关配送',
          type: 'bar',
          stack: '总量',
          data: []
        },{
          name: '其他配送',
          type: 'bar',
          stack: '总量',
          data: []
        }
      ]
    };

    dashboardDistrictDeliveryChart.setOption(district_delivery_option);

    let thatBeginTime = this.beginTimeValue;
    let thatEndTime = this.endTimeValue;

    setTimeout(() => {
      $.get('/chart/district_delivery.json', { begin_time: thatBeginTime, end_time: thatEndTime })
        .done(function (data) {
          dashboardDistrictDeliveryChart.setOption({
              title: {
                subtext: `${thatBeginTime} - ${thatEndTime} 配送总数 ${data.delivery_counts.reduce((a, b) => a + b, 0)}`
              },
              xAxis: {
                data: data.categories
              },
              series: [{
                  name: '合计',
                  data: data.delivery_counts
                },{
                  name: '未知',
                  data: data.unknown_counts
                },{
                  name: '社区配送',
                  data: data.community_distribute_counts
                },{
                  name: '楼宇配送',
                  data: data.building_floor_distribute_counts
                },{
                  name: '园区配送',
                  data: data.street_block_distribute_counts
                },{
                  name: '校区配送',
                  data: data.campus_distribute_counts
                },{
                  name: '机关配送',
                  data: data.jiguan_distribute_counts
                },{
                  name: '其他配送',
                  data: data.other_distribute_counts
                }
              ]
          });
      });
      dashboardDistrictDeliveryChart.resize();
    }, this.pauseValue);
  }

  layout() {
    dashboardDistrictDeliveryChart.resize();
  }

  disconnect() {
    dashboardDistrictDeliveryChart.dispose();
  }
}
