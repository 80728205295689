import { Controller } from "stimulus"

let subExpenseCharts = [];
let subDistrictIdsPerCharts = [];

export default class extends Controller {
  static targets = [ "chart" ];
  static values = { beginTime: String, endTime: String, districtId: Number, pause: Number };

  connect() {
    let thatDistrictId = this.districtIdValue;
    let thatBeginTime = this.beginTimeValue;
    let thatEndTime = this.endTimeValue;

    subExpenseCharts[thatDistrictId] = echarts.init(this.chartTarget);

    const subdistrict_expense_option = {
      title : {
        text: '各街道消费金额',
        subtext: '查询时间、消费金额',
        x:'center'
      },
      tooltip : {
        trigger: 'axis',
        axisPointer : {
          type : 'shadow'
        }
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          dataView: {},
          saveAsImage: {
            pixelRatio: 2
          }
        }
      },
      xAxis : [
        {
          type: 'category',
          axisLabel: {
            showMinLabel: true,
            rotate: -40,
            fontSize: 9,
          },
          data: [],
        }
      ],
      yAxis : [
        {
          type: 'value',
        }
      ],
      series : [
        {
          name: '消费金额',
          type: 'bar',
          data: [],
          barMaxWidth: 78,
        }
      ]
    };

    function drill_down_subdistrict_expense_option(params) {
      if (params.componentType === 'series') {
        if (params.seriesType === 'bar') {
          const subdistrict_id = subDistrictIdsPerCharts[thatDistrictId][params.dataIndex];
          const sent_data = { subdistrict_id, begin_time: thatBeginTime, end_time: thatEndTime };
          $.ajax('/chart/subdistrict_expense/drill_down.js', {
            data: sent_data,
            dataType: 'script'
          });
        }
      }
    }

    subExpenseCharts[thatDistrictId].setOption(subdistrict_expense_option);
    subExpenseCharts[thatDistrictId].on('click', drill_down_subdistrict_expense_option);

    setTimeout(() => {
      $.get('/chart/subdistrict_expense.json', { begin_time: thatBeginTime, end_time: thatEndTime, district_id: this.districtIdValue })
        .done(function (data) {
          subDistrictIdsPerCharts[thatDistrictId] = data.subdistrict_ids;
          subExpenseCharts[thatDistrictId].setOption({
            title: {
              text: `${data.district_title || '各街道'}消费金额`,
              subtext: `${thatBeginTime} - ${thatEndTime} 消费总金额 ${data.expense_sums.reduce((a, b) => a + b, 0)}`
            },
            xAxis: {
              data: data.categories
            },
            series: [{
              name: '消费金额',
              data: data.expense_sums
            }]
          });
        });
      subExpenseCharts[thatDistrictId].resize();
    }, this.pauseValue);
  }

  layout() {
    subExpenseCharts[this.districtIdValue].resize();
  }

  disconnect() {
    subExpenseCharts[this.districtIdValue].dispose();
  }
}
