import { Controller } from "stimulus"

export default class extends Controller {
  static values = { districtId: Number, expend: Number }

  toggle() {
    switch (this.expendValue) {
      case 1:
        this.expendValue = 0;
        $(`#accordion-district-${this.districtIdValue}`).children().last().collapse('hide');
        break;
      case 0:
        this.expendValue = 1;
        $(`#accordion-district-${this.districtIdValue}`).children().last().collapse('show');
        break;
      default:
        $.ajax('/ui/district', {
          data: { district_id: this.districtIdValue },
          dataType: 'script'
        });
        this.expendValue = 1;
    }
  }
}
